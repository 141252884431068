import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { LuMoveRight } from "react-icons/lu";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Navigation } from "swiper/modules";

function Banner() {
  const sliders = [
    {
      img: require("../../assets/banners/2.webp"),
    },
    {
      img: require("../../assets/banners/3.webp"),
    },
    {
      img: require("../../assets/banners/fronx.webp"),
    },
    {
      img: require("../../assets/banners/jimny.webp"),
    },
    {
      img: require("../../assets/banners/1.webp"),
    },
    {
      img: require("../../assets/banners/swift.webp"),
    },
  ];

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  return (
    <div className="">
      <Swiper
        spaceBetween={30}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={{
          nextEl: navigationNextRef.current,
          prevEl: navigationPrevRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        loop={true}
        modules={[Autoplay, Navigation]}
        className="mySwiper slider-home"
      >
        {sliders?.map((item, i) => (
          <SwiperSlide key={i}>
            <img
              src={item.img}
              loading="lazy"
              className={`max-w-full w-full object-cover h-[700px]`}
              alt={i}
            />
          </SwiperSlide>
        ))}

        <div
          ref={navigationPrevRef}
          className="absolute left-3 sm:left-10 top-1/2 sm:top-1/2 z-10 bg-white rounded-full p-1 sm:p-3 cursor-pointer"
        >
          <LuMoveRight className="rotate-180" />
        </div>
        <div
          ref={navigationNextRef}
          className="absolute right-3 sm:right-10 top-1/2 sm:top-1/2 z-10 bg-white rounded-full p-1 sm:p-3 cursor-pointer"
        >
          <LuMoveRight />
        </div>
      </Swiper>
    </div>
  );
}

export default Banner;
