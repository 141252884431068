import React from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/navbar/Header";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
      <Helmet>
        <title>
          Trusted Maruti Suzuki Car Dealers in Hyderabad |Maruti Suzuki Cars
          Hyderabad
        </title>
        <meta
          name="title"
          content="Trusted Maruti Suzuki Car Dealers in Hyderabad |Maruti Suzuki Cars Hyderabad"
        />
        <meta
          name="description"
          content="Gem Maruti Trusted Maruti Suzuki Car Dealers in Hyderabad. Viist Nearest Gem Maruti Suzuki Showroom Today and test drive your favourite Maruti car."
        />
      </Helmet>
      <Header />
      <div className="px-5 lg:px-0">
        <div className="container mx-auto mt-16 mb-8">
          <div className="flex justify-center">
            <p className="text-red-600 p-2">About us</p>
          </div>
          <p className="uppercase font-semibold text-4xl text-center mt-3">
            Welcome to GEM motors
          </p>
          <div className="relative">
            <div className="h-1 bg-gray-500 mt-3 max-w-[100px] mx-auto"></div>
          </div>
          <p className="mt-8 max-w-3xl text-center mx-auto">
            Take great pride in creating, what we believe, to be one of the most
            premier dealerships across Telangana State.
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 container border rounded-lg shadow-lg p-5 mx-auto my-7">
          <div className="col-span-1">
            <img
              src={require("../assets/nexa/grandvitara.webp")}
              className="mx-auto"
              loading="lazy"
              alt=""
            />
          </div>
          <div className="col-span-1">
            <p className="font-semibold lg:text-4xl">
              Welcome To The Most <br />{" "}
              <span className="text-red-600">
                Youngest Car Dealer in Hyderabad
              </span>
            </p>
            <p className="mt-8">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugit
              deleniti magni aperiam, quas quos vel sapiente ad aliquam et sit
              atque! Soluta, temporibus id unde facilis asperiores quos ducimus
              autem.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto my-10 border rounded-lg shadow-lg p-5">
          <div className="col-span-1">
            <p className="font-semibold lg:text-4xl">
              GEM Motors Makes you <br />{" "}
              <span className="text-red-600">happy….Deeply!</span>
            </p>
            <p className="mt-8">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio
              harum, quia neque, ipsam amet vero facilis, adipisci optio rerum
              quaerat doloribus error odit necessitatibus saepe assumenda culpa.
              Modi, provident est!
            </p>
          </div>
          <div className="col-span-1">
            <img
              src={require("../assets/nexa/xl6.webp")}
              loading="lazy"
              className="mx-auto max-h-96 h-full"
              alt=""
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
