export const models = [
  //invicto
  {
    id: "invicto",
    category: "nexa",
    name: "Maruti Invicto",
    tag: "Created to Inspire the Extraordinary",
    metaTitle:
      "Nexa Invicto Price in Hyderabad | Maruti Invicto 6 Seater Offers Hyderabad",
    metaDescription:
      "Nexa Invocto On road Price in Hyderabad. Maruti Suzuki Invicto Offers. For more details about Invicto features, specifications, mileage, colors and images visit our site or call now.",
    pic: require("../assets/nexa/invicto.webp"),
    price: 2482000,
    fuel: "Petrol / Hybrid",
    transmission: "e-CVT",
    cc: "1987",
    variants: [
      {
        variant: "Zeta+ 7 STR",
        price: 2482000,
      },
      {
        variant: "Zeta+ 8 STR	",
        price: 2487000,
      },
      {
        variant: "Alpha+ 7STR	",
        price: 2842000,
      },
    ],
    banners: [
      {
        img: require("../assets/invicto/1.webp"),
      },
      {
        img: require("../assets/invicto/2.webp"),
      },
      {
        img: require("../assets/invicto/3.webp"),
      },
      {
        img: require("../assets/invicto/4.webp"),
      },
      {
        img: require("../assets/invicto/5.webp"),
      },
      {
        img: require("../assets/invicto/6.webp"),
      },
      {
        img: require("../assets/invicto/7.webp"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //should be 6 items or max 6 items
      {
        title: "Length (mm)",
        value: 4755,
      },
      {
        title: "Width (mm)",
        value: 1845,
      },
      {
        title: "Height (mm)",
        value: 1795,
      },
      {
        title: "Fuel Tank(L)",
        value: 52,
      },
      {
        title: "Seating",
        value: 7,
      },
      {
        title: "Bootspace(L)",
        value: 318,
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: 1987,
      },
      {
        title: "Max Power",
        value: "112kw@6000",
      },
      {
        title: "Fuel Type",
        value: "Petrol / Hybrid",
      },
      {
        title: "Hybrid Motor",
        value: "AC Synchronous Motor",
      },
      {
        title: "Hybrid Max Power",
        value: "83.73kw@4000",
      },
      {
        title: "Transmission",
        value: "e-CVT",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "Ventilated Disc",
      },
      {
        title: "Rear",
        value: "Solid Disc",
      },
      {
        title: "Kerb",
        value: "1620/1630",
      },
      {
        title: "Gross Weight",
        value: 2300,
      },
    ],
  },

  //fronx
  {
    metaTitle:
      "Nexa Fronx Price in Hyderabad | Maruti Fronx CNG Offers & Discounts",
    metaDescription:
      "Nexa Fronx on road price in Hyderabad. Maruti Fronx CNG On Road price. Maruti Fronx is now available with 1.2litre DualJet, Dual VVT, BS6 engine with Smart Hybrid tech. Visit your nearest Gem Motors showroom for Nexa Fronx offers & discounts. Call now.",
    id: "fronx",
    category: "nexa",
    name: "Maruti Fronx",
    tag: "Created to Inspire Style and Performance",
    pic: require("../assets/nexa/fronx.png"),
    price: 746500,
    fuel: "Petrol / CNG",
    transmission: "5MT / 5AMT",
    cc: "1197",
    variants: [
      {
        variant: "Sigma",
        price: 746500,
      },
      {
        variant: "Delta",
        price: 832500,
      },
      {
        variant: "Delta+",
        price: 872500,
      },
      {
        variant: "Delta AGS",
        price: 887500,
      },
      {
        variant: "Delta+ AGS",
        price: 927500,
      },
      {
        variant: "Delta+ MT (SH)",
        price: 972500,
      },
      {
        variant: "Zeta MT (SH)",
        price: 1055500,
      },
      {
        variant: "Alpha(M) MT (SH)",
        price: 1147500,
      },
      {
        variant: "Alpha(D) MT (SH)",
        price: 1163500,
      },
      {
        variant: "Zeta AT (SH)",
        price: 1205500,
      },
      {
        variant: "Alpha M (SH)",
        price: 1297500,
      },
      {
        variant: "Alpha(D) AT (SH)",
        price: 1313500,
      },
      {
        variant: "Sigma CNG",
        price: 841500,
      },
      {
        variant: "Delta CNG",
        price: 927500,
      },
    ],
    banners: [
      {
        img: require("../assets/fronx/1.webp"),
      },
      {
        img: require("../assets/fronx/2.webp"),
      },
      {
        img: require("../assets/fronx/3.webp"),
      },
      {
        img: require("../assets/fronx/4.webp"),
      },
      {
        img: require("../assets/fronx/5.webp"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //should be 6 items or max 6 items
      {
        title: "Length (mm)",
        value: 3995,
      },
      {
        title: "Width (mm)",
        value: 1765,
      },
      {
        title: "Height (mm)",
        value: 1550,
      },
      {
        title: "Fuel Tank(L)",
        value: "37",
      },
      {
        title: "Seating",
        value: 5,
      },
      {
        title: "Bootspace(L)",
        value: 308,
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: 1197,
      },
      {
        title: "Engine Type",
        value: "1.2L Dual Jet, Dual VVT",
      },
      {
        title: "Cylinders",
        value: 4,
      },
      {
        title: "Max Power",
        value: "66kw@6000",
      },
      {
        title: "Max Torque",
        value: "113@4400",
      },
      {
        title: "Transmission",
        value: "5MT / 5AMT / 6AT",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "Disc",
      },
      {
        title: "Rear",
        value: "Drum",
      },
      {
        title: "Kerb",
        value: "965-970(MT),975(AMT)",
      },
      {
        title: "Gross Weight",
        value: 1450,
      },
    ],
  },

  //jimny
  {
    metaTitle: "Nexa Jimny Off Road Car Petrol Price & Mileage in Hyderabad",
    metaDescription:
      "Nexa Jimny available in Petrol & CNG Variants. Jimny Off road Price. For More info about Maruti Suzuki Jimny Features, Specifications, Mileage, Colors & Images Call us.",
    id: "jimny",
    category: "nexa",
    name: "Maruti Jimny",
    tag: "Created for Purity of Function",
    pic: require("../assets/nexa/jimny.png"),
    price: 1274000,
    fuel: "Petrol",
    transmission: "5MT / 4AT",
    cc: "1462",
    variants: [
      {
        variant: "Zeta",
        price: 1274000,
      },
      {
        variant: "Alpha(M)",
        price: 1369000,
      },
      {
        variant: "Alpha(D)",
        price: 1385000,
      },
      {
        variant: "Zeta 4AT",
        price: 1394000,
      },
      {
        variant: "ALPHA(M) 4AT",
        price: 1489000,
      },
      {
        variant: "ALPHA(D) 4AT",
        price: 1505000,
      },
    ],
    banners: [
      {
        img: require("../assets/jimny/1.webp"),
      },
      {
        img: require("../assets/jimny/2.webp"),
      },
      {
        img: require("../assets/jimny/3.webp"),
      },
      {
        img: require("../assets/jimny/4.webp"),
      },
      {
        img: require("../assets/jimny/5.webp"),
      },
      {
        img: require("../assets/jimny/6.webp"),
      },
      {
        img: require("../assets/jimny/7.webp"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //should be 6 items or max 6 items
      {
        title: "Length (mm)",
        value: 3985,
      },
      {
        title: "Width (mm)",
        value: 1645,
      },
      {
        title: "Height (mm)",
        value: 1720,
      },
      {
        title: "Fuel Tank(L)",
        value: "37",
      },
      {
        title: "Seating",
        value: 4,
      },
      {
        title: "Bootspace(L)",
        value: 219,
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: 1462,
      },
      {
        title: "Engine Type",
        value: "K15B with Idle Start Stop",
      },
      {
        title: "Cylinders",
        value: 4,
      },
      {
        title: "Max Power",
        value: "77.1kw@6000",
      },
      {
        title: "Max Torque",
        value: "134.2@4000",
      },
      {
        title: "Transmission",
        value: "5MT / 4AT",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "Disc",
      },
      {
        title: "Rear",
        value: "Drum",
      },
      {
        title: "Kerb",
        value: "1195-1210",
      },
      {
        title: "Gross Weight",
        value: 1545,
      },
    ],
  },

  //grand vitara
  {
    metaTitle:
      "Nexa Grand Vitara Petrol Price & Mileage in Hyderabad |Grand Vitara CNG Price",
    metaDescription:
      "Nexa Grand Vitara available in Petrol & CNG Variants. Grand Vitara Price. For More info about Maruti Suzuki Grand Vitara Features, Specifications, Mileage, Colors & Images Call us.",
    id: "grandvitara",
    category: "nexa",
    name: "Maruti Grand Vitara",
    tag: "Create. Inspire",
    pic: require("../assets/nexa/grandvitara.webp"),
    price: 1070000,
    fuel: "Petrol",
    transmission: "5MT / 6AT",
    cc: "1462",
    variants: [
      {
        variant: "Sigma",
        price: 1070000,
      },
      {
        variant: "Delta",
        price: 1210000,
      },
      {
        variant: "Zeta",
        price: 1391000,
      },
      {
        variant: "Alpha",
        price: 1541000,
      },
      {
        variant: "Alpha (DT)",
        price: 1557000,
      },
      {
        variant: "Delta AT",
        price: 1360000,
      },
      {
        variant: "Zeta AT",
        price: 1541000,
      },
      {
        variant: "Alpha AT",
        price: 1691000,
      },
      {
        variant: "Alpha AT(DT)",
        price: 1707000,
      },
      {
        variant: "Delta CNG",
        price: 1305000,
      },
      {
        variant: "Zeta CNG",
        price: 1486000,
      },
    ],
    banners: [
      {
        img: require("../assets/vitara/1.webp"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //should be 6 items or max 6 items
      {
        title: "Length (mm)",
        value: 4345,
      },
      {
        title: "Width (mm)",
        value: 1795,
      },
      {
        title: "Height (mm)",
        value: 1645,
      },
      {
        title: "Fuel Tank(L)",
        value: 45,
      },
      {
        title: "Seating",
        value: 5,
      },
      {
        title: "Bootspace(L)",
        value: "-",
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: 1462,
      },
      {
        title: "Engine Type",
        value: "-",
      },
      {
        title: "Cylinders",
        value: 4,
      },
      {
        title: "Max Power",
        value: "75.8kw@6000",
      },
      {
        title: "Max Torque",
        value: "136.8@4400",
      },
      {
        title: "Transmission",
        value: "5MT / 6AT / e-CVT",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "Ventilated Disc",
      },
      {
        title: "Rear",
        value: "Solid Disc",
      },
      {
        title: "Kerb",
        value: "1150-1185(MT)",
      },
      {
        title: "Gross Weight",
        value: 1410,
      },
    ],
  },

  //baleno
  {
    metaTitle:
      "Nexa Baleno Price in Hyderabad | Maruti Baleno CNG Offers & Discounts",
    metaDescription:
      "Nexa Baleno  on road price in Hyderabad. Maruti Baleno CNG On Road price. Maruti Baleno is now available with 1.2litre DualJet, Dual VVT, BS6 engine with Smart Hybrid tech. Visit your nearest Gem Motors showroom for Nexa Baleno offers & discounts. Call now.",
    id: "baleno",
    category: "nexa",
    name: "Maruti Baleno",
    tag: "Created to Inspire The Bold and Intelligent",
    pic: require("../assets/nexa/baleno.png"),
    price: 661000,
    fuel: "Petrol",
    transmission: "5MT / 5AMT",
    cc: "1197",
    variants: [
      {
        variant: "Sigma",
        price: 661000,
      },
      {
        variant: "Delta",
        price: 745000,
      },
      {
        variant: "Zeta",
        price: 838000,
      },
      {
        variant: "Alpha",
        price: 933000,
      },
      {
        variant: "Delta CNG",
        price: 835000,
      },
      {
        variant: "Zeta CNG",
        price: 928000,
      },
      {
        variant: "Delta AT",
        price: 800000,
      },
      {
        variant: "Zeta AT",
        price: 893000,
      },
      {
        variant: "Alpha AT",
        price: 988000,
      },
    ],
    banners: [
      {
        img: require("../assets/baleno/1.webp"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //should be 6 items or max 6 items
      {
        title: "Length (mm)",
        value: 3990,
      },
      {
        title: "Width (mm)",
        value: 1745,
      },
      {
        title: "Height (mm)",
        value: 1500,
      },
      {
        title: "Fuel Tank(L)",
        value: 37,
      },
      {
        title: "Seating",
        value: 5,
      },
      {
        title: "Bootspace(L)",
        value: 318,
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: 1197,
      },
      {
        title: "Engine Type",
        value: "Adv. K Series Dual Jet / Dual VVT Engine",
      },
      {
        title: "Cylinders",
        value: 4,
      },
      {
        title: "Max Power",
        value: "66kw@6000",
      },
      {
        title: "Max Torque",
        value: "113@4400",
      },
      {
        title: "Transmission",
        value: "5MT / 5AMT",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "Disc",
      },
      {
        title: "Rear",
        value: "Drum",
      },
      {
        title: "Kerb",
        value: "925-955(MT),940-960(AMT)",
      },
      {
        title: "Gross Weight",
        value: 1410,
      },
    ],
  },

  //xl6
  {
    metaTitle:
      "Nexa XL6 Price in Hyderabad | Maruti XL6 6 Seater Offers Hyderabad",
    metaDescription:
      "Nexa XL6 On road Price in Hyderabad. Maruti Suzuki XL6 Offers. For more details about XL6 features, specifications, mileage, colors and images visit our site or call now.",
    id: "xl6",
    category: "nexa",
    name: "Maruti XL6",
    tag: "Created to Inspire the Indulgence.",
    pic: require("../assets/nexa/xl6.webp"),
    price: 1156000,
    fuel: "Petrol",
    transmission: "5MT / 6AT",
    cc: "1462",
    variants: [
      {
        variant: "Zeta",
        price: 1156000,
      },
      {
        variant: "Alpha",
        price: 1256000,
      },
      {
        variant: "Alpha+",
        price: 1316000,
      },
      {
        variant: "ZETA AT",
        price: 1306000,
      },
      {
        variant: "Alpha AT",
        price: 1406000,
      },
      {
        variant: "Alpha+ AT",
        price: 1466000,
      },
      {
        variant: "Zeta CNG 5MT",
        price: 1251000,
      },
    ],
    banners: [
      {
        img: require("../assets/xl6/1.webp"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //should be 6 items or max 6 items
      {
        title: "Length (mm)",
        value: 4445,
      },
      {
        title: "Width (mm)",
        value: 1775,
      },
      {
        title: "Height (mm)",
        value: 1755,
      },
      {
        title: "Fuel Tank(L)",
        value: 45,
      },
      {
        title: "Seating",
        value: 6,
      },
      {
        title: "Bootspace(L)",
        value: "-",
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: 1462,
      },
      {
        title: "Engine Type",
        value: "K15C Smart Hybrid",
      },
      {
        title: "Cylinders",
        value: 4,
      },
      {
        title: "Max Power",
        value: "75.8kw@6000",
      },
      {
        title: "Max Torque",
        value: "136.8@4400",
      },
      {
        title: "Transmission",
        value: "5MT / 6AT",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "Disc",
      },
      {
        title: "Rear",
        value: "Drum",
      },
      {
        title: "Kerb",
        value: "1200 (MT), 1225 (AT)",
      },
      {
        title: "Gross Weight",
        value: "1740(MT), 1765(AT)",
      },
    ],
  },

  //ciaz
  {
    metaTitle:
      "Nexa Ciaz Car On Road Price, Mileage, Specifications & Offers | Gem Maruti",
    metaDescription:
      "Nexa Ciaz Car On road Price, Mileage of 26.8 km/l. Get Nexa Ciaz Specifications & Features. Best Offers on Ciaz Car, Low EMI, Low Down-Payment. Call now.",
    id: "ciaz",
    category: "nexa",
    name: "Maruti Ciaz",
    tag: "Created to Inspire Elegance.",
    pic: require("../assets/nexa/ciaz.webp"),
    price: 930000,
    fuel: "Petrol",
    transmission: "5MT / 4AT",
    cc: "1462",
    variants: [
      {
        variant: "Sigma MT",
        price: 930000,
      },
      {
        variant: "Delta MT",
        price: 990000,
      },
      {
        variant: "Zeta MT",
        price: 1030000,
      },
      {
        variant: "Alpha MT",
        price: 1109000,
      },
      {
        variant: "Sigma AT",
        price: 1156000,
      },
      {
        variant: "Delta AT",
        price: 1110000,
      },
      {
        variant: "Zeta AT",
        price: 1150000,
      },
      {
        variant: "Alpha AT",
        price: 1229000,
      },
    ],
    banners: [
      {
        img: require("../assets/ciaz/1.webp"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //should be 6 items or max 6 items
      {
        title: "Length (mm)",
        value: 4490,
      },
      {
        title: "Width (mm)",
        value: 1730,
      },
      {
        title: "Height (mm)",
        value: "-",
      },
      {
        title: "Fuel Tank(L)",
        value: 43,
      },
      {
        title: "Seating",
        value: 5,
      },
      {
        title: "Bootspace(L)",
        value: "-",
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: "-",
      },
      {
        title: "Engine Type",
        value: "K15C Smart Hybrid",
      },
      {
        title: "Cylinders",
        value: 4,
      },
      {
        title: "Max Power",
        value: "77kw@6000",
      },
      {
        title: "Max Torque",
        value: "138@4400",
      },
      {
        title: "Transmission",
        value: "5MT / 4AT",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "Ventialted Disc",
      },
      {
        title: "Rear",
        value: "Drum",
      },
      {
        title: "Kerb",
        value: "-",
      },
      {
        title: "Gross Weight",
        value: "1520 (MT), 1530 (AT)",
      },
    ],
  },

  //ignis
  {
    metaTitle: "Nexa Ignis On road Price & Offers in Hyderabad | Gem Motors",
    metaDescription:
      "Get latest offers on Nexa Ignis. Maruti Ignis On road Price, Mileage of 24.3 Km/l & available in 4 variants. Call now for Nexa Ignis Offers.",
    id: "ignis",
    category: "nexa",
    name: "Maruti Ignis",
    tag: "Created to Inspire the Toughness in you.",
    pic: require("../assets/nexa/ignis.webp"),
    price: 584000,
    fuel: "Petrol",
    transmission: "5 MT/AMT",
    cc: "1197",
    variants: [
      {
        variant: "Sigma MT",
        price: 584000,
      },
      {
        variant: "Delta MT",
        price: 638000,
      },
      {
        variant: "Zeta MT",
        price: 696000,
      },
      {
        variant: "Delta AGS",
        price: 693000,
      },
      {
        variant: "Zeta AGS",
        price: 751000,
      },
      {
        variant: "Alpha MT",
        price: 761000,
      },
      {
        variant: "Alpha AGS",
        price: 816000,
      },
    ],
    banners: [
      {
        img: require("../assets/ignis/1.webp"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //max 6 items
      {
        title: "Length (mm)",
        value: 3700,
      },
      {
        title: "Width (mm)",
        value: 1690,
      },
      {
        title: "Height (mm)",
        value: "1595",
      },
      {
        title: "Fuel Tank(L)",
        value: 32,
      },
      {
        title: "Seating",
        value: 5,
      },
      {
        title: "Bootspace(L)",
        value: 260,
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: "1197",
      },
      {
        title: "Engine Type",
        value: "VVT",
      },
      {
        title: "Cylinders",
        value: 4,
      },
      {
        title: "Max Power",
        value: "61kw@6000",
      },
      {
        title: "Max Torque",
        value: "113@4200",
      },
      {
        title: "Transmission",
        value: "5 MT/AMT",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "Disc",
      },
      {
        title: "Rear",
        value: "Drum",
      },
      {
        title: "Kerb",
        value: "840-865",
      },
      {
        title: "Gross Weight",
        value: "-",
      },
    ],
  },

  //alto
  {
    metaTitle:
      "Maruti Suzuki alto 800 On Road Price| Maruti alto 800 Offers | alto 800 Mileage",
    metaDescription:
      "Maruti Suzuki alto 800 on road price in Hyderabad. Visit nearest Gem Maruti Suzuki Showroom to get Offers on alto 800. Maruti Suzuki alto 800 Mileage. Book A Test Drive",
    id: "alto",
    category: "arena",
    name: "Maruti Alto",
    tag: "Experience the captivating design of the new Alto, which will give you a sense of pride every time you go for a drive.",
    pic: require("../assets/arena/alto.webp"),
    price: 354000,
    fuel: "Petrol",
    transmission: "5MT",
    cc: "796",
    variants: [
      {
        variant: "STD",
        price: 354000,
      },
      {
        variant: "LXI",
        price: 423000,
      },
      {
        variant: "VXI",
        price: 443000,
      },
      {
        variant: "VXI+",
        price: 456500,
      },
      {
        variant: "LXI CNG",
        price: 513500,
      },

      {
        variant: "Tour H1",
        price: 420000,
      },
    ],
    banners: [
      {
        img: require("../assets/alto/1.webp"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //max 6 items
      {
        title: "Length (mm)",
        value: 3445,
      },
      {
        title: "Width (mm)",
        value: 1490,
      },
      {
        title: "Height (mm)",
        value: "1515",
      },
      {
        title: "Fuel Tank(L)",
        value: 35,
      },
      {
        title: "Seating",
        value: 4,
      },
      {
        title: "Bootspace(L)",
        value: "-",
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: "796",
      },
      {
        title: "Engine Type",
        value: "F8D",
      },
      {
        title: "Cylinders",
        value: 3,
      },
      {
        title: "Max Power",
        value: "35.3kw@6000",
      },
      {
        title: "Max Torque",
        value: "69@3500",
      },
      {
        title: "Transmission",
        value: "5MT",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "Disc",
      },
      {
        title: "Rear",
        value: "Drum",
      },
      {
        title: "Kerb",
        value: "730 - 850",
      },
      {
        title: "Gross Weight",
        value: "1185",
      },
    ],
  },

  //alto k10
  {
    metaTitle:
      "Maruti Suzuki Alto K10 On Road Price| Maruti Alto K10 Offers | Alto K10 Mileage",
    metaDescription:
      "Maruti Suzuki Alto K10 on road price in Hyderabad. Visit nearest Gem Maruti Suzuki Showroom to get Offers on Alto K10. Maruti Suzuki Alto K10 Mileage. Book A Test Drive",
    id: "alto-k10",
    category: "arena",
    name: "Maruti Alto K10",
    tag: "India ki Chal Padi",
    pic: require("../assets/arena/k10.webp"),
    price: 399000,
    fuel: "Petrol",
    transmission: "5MT / AGS",
    cc: "998",
    variants: [
      {
        variant: "STD",
        price: 399000,
      },
      {
        variant: "LXI",
        price: 483500,
      },
      {
        variant: "VXI",
        price: 506000,
      },
      {
        variant: "VXI CNG",
        price: 596000,
      },
      {
        variant: "VXI+",
        price: 535000,
      },
      {
        variant: "VXI AGS",
        price: 556000,
      },
      {
        variant: "VXI+ AGS",
        price: 585000,
      },
    ],
    banners: [
      {
        img: require("../assets/k10/1.webp"),
      },
      {
        img: require("../assets/k10/2.webp"),
      },
      {
        img: require("../assets/k10/3.webp"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //max 6 items
      {
        title: "Length (mm)",
        value: 3530,
      },
      {
        title: "Width (mm)",
        value: 1490,
      },
      {
        title: "Height (mm)",
        value: "1520",
      },
      {
        title: "Fuel Tank(L)",
        value: 27,
      },
      {
        title: "Seating",
        value: 5,
      },
      {
        title: "Bootspace(L)",
        value: 214,
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: "998",
      },
      {
        title: "Engine Type",
        value: "K10C",
      },
      {
        title: "Cylinders",
        value: 4,
      },
      {
        title: "Max Power",
        value: "49kw@6000",
      },
      {
        title: "Max Torque",
        value: "89@4200",
      },
      {
        title: "Transmission",
        value: "5MT / AGS",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "Disc",
      },
      {
        title: "Rear",
        value: "Drum",
      },
      {
        title: "Kerb",
        value: "-",
      },
      {
        title: "Gross Weight",
        value: "-",
      },
    ],
  },

  //s-presso
  {
    metaTitle: "Maruti Suzuki Spresso Price Hyderabad | Offers on Spresso",
    metaDescription:
      "Maruti Suuzki Spresso Compatable SUV On Road Price in Hyderabad. Maruti Spresso Offers. Spresso Dpecifications & Features.visit Now.",
    id: "s-presso",
    category: "arena",
    name: "Maruti S.presso",
    tag: "Styled for xtra excitement.",
    pic: require("../assets/arena/Spresso.webp"),
    price: 426500,
    fuel: "Petrol",
    transmission: "5MT / AGS",
    cc: "998",
    variants: [
      {
        variant: "STD",
        price: 426500,
      },
      {
        variant: "LXI",
        price: 501500,
      },
      {
        variant: "VXI",
        price: 521500,
      },
      {
        variant: "LXI CNG",
        price: 591500,
      },
      {
        variant: "VXI CNG",
        price: 611500,
      },
      {
        variant: "VXI+",
        price: 550500,
      },
      {
        variant: "VXI AGS",
        price: 571500,
      },
      {
        variant: "VXI+ AGS",
        price: 600500,
      },
    ],
    banners: [
      {
        img: require("../assets/spresso/1.png"),
      },
      {
        img: require("../assets/spresso/2.webp"),
      },
      {
        img: require("../assets/spresso/3.webp"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //max 6 items
      {
        title: "Length (mm)",
        value: 3565,
      },
      {
        title: "Width (mm)",
        value: 1520,
      },
      {
        title: "Height (mm)",
        value: "1553",
      },
      {
        title: "Fuel Tank(L)",
        value: 27,
      },
      {
        title: "Seating",
        value: 5,
      },
      {
        title: "Bootspace(L)",
        value: "-",
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: "998",
      },
      {
        title: "Engine Type",
        value: "K10C",
      },
      {
        title: "Cylinders",
        value: "-",
      },
      {
        title: "Max Power",
        value: "49@5500",
      },
      {
        title: "Max Torque",
        value: "89@3500",
      },
      {
        title: "Transmission",
        value: "5MT / AGS",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "Ventilatd Disc",
      },
      {
        title: "Rear",
        value: "Drum",
      },
      {
        title: "Kerb",
        value: "736-775",
      },
      {
        title: "Gross Weight",
        value: "1170",
      },
    ],
  },

  // brezza
  {
    metaTitle:
      "Maruti Suzuki Brezza On Road Price Hyderabad | Brezza CNG Price | Offers on Maruti Brezza",
    metaDescription:
      "Maruti Suzuki Brezza Most Selling SUV in Hyderabad, Comest with Sunroof, 6 air bags & advanced safety features. Call now for Maruti Brezza Offers. Brezza available in Petrol & CNG Variants. Brezza CNG Price",
    id: "brezza",
    category: "arena",
    name: "Maruti Brezza",
    tag: "Step in to stand out.",
    pic: require("../assets/arena/brezza.webp"),
    price: 834000,
    fuel: "Petrol / CNG",
    transmission: "5MT / 6AT",
    cc: "1462",
    variants: [
      {
        variant: "K15C 5MT LXi",
        price: 834000,
      },
      {
        variant: "LXI CNG MT",
        price: 929000,
      },
      {
        variant: "K15C 5MT VXi",
        price: 969500,
      },
      {
        variant: "VXI CNG MT",
        price: 1064500,
      },
      {
        variant: "K15C 6AT VXi",
        price: 1109500,
      },
      {
        variant: "K15C 5MT ZXi",
        price: 1114500,
      },
      {
        variant: "ZXI CNG MT",
        price: 1209500,
      },
      {
        variant: "K15C 6AT ZXi",
        price: 1254500,
      },
      {
        variant: "K15C 5MT ZXi+",
        price: 1258000,
      },
      {
        variant: "K15C 6AT ZXi+",
        price: 1398000,
      },
    ],
    banners: [
      {
        img: require("../assets/brezza/1.webp"),
      },
      {
        img: require("../assets/brezza/2.webp"),
      },
      {
        img: require("../assets/brezza/3.webp"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //max 6 items
      {
        title: "Length (mm)",
        value: 3995,
      },
      {
        title: "Width (mm)",
        value: 1790,
      },
      {
        title: "Height (mm)",
        value: "1685",
      },
      {
        title: "Fuel Tank(L)",
        value: 48,
      },
      {
        title: "Seating",
        value: 5,
      },
      {
        title: "Bootspace(L)",
        value: "328",
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: "1462",
      },
      {
        title: "Engine Type",
        value: "K15C Smart Hybrid",
      },
      {
        title: "Cylinders",
        value: "-",
      },
      {
        title: "Max Power",
        value: "75.8@6000",
      },
      {
        title: "Max Torque",
        value: "136.8@3500",
      },
      {
        title: "Transmission",
        value: "5MT / 6AT",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "Ventilatd Disc",
      },
      {
        title: "Rear",
        value: "Drum",
      },
      {
        title: "Kerb",
        value: "-",
      },
      {
        title: "Gross Weight",
        value: "-",
      },
    ],
  },

  // celerio
  {
    metaTitle:
      "Maruti Suzuki Celerio On Road Price| Maruti Celerio Offers | Celerio Mileage",
    metaDescription:
      "Maruti Suzuki Celerio on road price in Hyderabad. Visit nearest Gem Maruti Suzuki Showroom to get Offers on Celerio. Maruti Suzuki Celerio Mileage. Book A Test Drive",
    id: "celerio",
    category: "arena",
    name: "Maruti Celerio",
    tag: "Drive your Style Celerio",
    pic: require("../assets/arena/celerio.webp"),
    price: 536500,
    fuel: "Petrol",
    transmission: "5MT",
    cc: "998",
    variants: [
      {
        variant: "LXI 1L ISS 5MT",
        price: 536500,
      },
      {
        variant: "VXI 1L ISS 5MT",
        price: 583500,
      },
      {
        variant: "ZXI 1L ISS 5MT",
        price: 611500,
      },
      {
        variant: "VXI 1L ISS AGS",
        price: 633500,
      },
      {
        variant: "ZXI+ 1L ISS 5MT",
        price: 659500,
      },
      {
        variant: "ZXI 1L ISS AGS",
        price: 661500,
      },
      {
        variant: "VXI CNG 1L 5MT",
        price: 673500,
      },
      {
        variant: "ZXI+ 1L ISS AGS",
        price: 709500,
      },
    ],
    banners: [
      {
        img: require("../assets/celerio/1.webp"),
      },
      {
        img: require("../assets/celerio/2.webp"),
      },
      {
        img: require("../assets/celerio/3.webp"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //max 6 items
      {
        title: "Length (mm)",
        value: 3695,
      },
      {
        title: "Width (mm)",
        value: 1655,
      },
      {
        title: "Height (mm)",
        value: "1555",
      },
      {
        title: "Fuel Tank(L)",
        value: 32,
      },
      {
        title: "Seating",
        value: 5,
      },
      {
        title: "Bootspace(L)",
        value: "313",
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: "998",
      },
      {
        title: "Engine Type",
        value: "K10C",
      },
      {
        title: "Cylinders",
        value: "3",
      },
      {
        title: "Max Power",
        value: "49@5500",
      },
      {
        title: "Max Torque",
        value: "89@3500",
      },
      {
        title: "Transmission",
        value: "5MT",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "Ventilatd Disc",
      },
      {
        title: "Rear",
        value: "Drum",
      },
      {
        title: "Kerb",
        value: "800-825",
      },
      {
        title: "Gross Weight",
        value: "1260",
      },
    ],
  },

  // dzire
  {
    metaTitle:
      "Maruti Suzuki Dzire Car Price Hyderabad | New Maruti Dzire Sunroof | Dzire Car Offers",
    metaDescription:
      "New Maruti Suzuki Dzire come with sunroof, Maruti dzire on road price, Swift Dzire offers in Hyderabad. Call now for more info ",
    id: "dzire",
    category: "arena",
    name: "Maruti Dzire",
    tag: "Move up to a new world of Style",
    pic: require("../assets/arena/dzire.webp"),
    price: 656500,
    fuel: "Petrol / CNG",
    transmission: "5MT / 5AMT",
    cc: "1197",
    variants: [
      {
        variant: "LXI",
        price: 656500,
      },
      {
        variant: "VXI",
        price: 749250,
      },
      {
        variant: "VXI AGS",
        price: 799250,
      },
      {
        variant: "ZXI",
        price: 817250,
      },
      {
        variant: "VXI CNG",
        price: 844250,
      },
      {
        variant: "ZXI AGS",
        price: 867250,
      },
      {
        variant: "ZXI+",
        price: 888750,
      },
      {
        variant: "ZXI CNG",
        price: 912250,
      },
      {
        variant: "ZXI+ AGS",
        price: 938750,
      },
    ],
    banners: [
      {
        img: require("../assets/dzire/1.webp"),
      },
      {
        img: require("../assets/dzire/2.webp"),
      },
      {
        img: require("../assets/dzire/3.webp"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //max 6 items
      {
        title: "Length (mm)",
        value: 3995,
      },
      {
        title: "Width (mm)",
        value: 1735,
      },
      {
        title: "Height (mm)",
        value: "1515",
      },
      {
        title: "Fuel Tank(L)",
        value: 37,
      },
      {
        title: "Seating",
        value: 5,
      },
      {
        title: "Bootspace(L)",
        value: "378",
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: "1197",
      },
      {
        title: "Engine Type",
        value: "-",
      },
      {
        title: "Cylinders",
        value: "4",
      },
      {
        title: "Max Power",
        value: "66@6000",
      },
      {
        title: "Max Torque",
        value: "113@3500",
      },
      {
        title: "Transmission",
        value: "5MT / 5AMT",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "Disc",
      },
      {
        title: "Rear",
        value: "Drum",
      },
      {
        title: "Kerb",
        value: "800-915",
      },
      {
        title: "Gross Weight",
        value: "1335",
      },
    ],
  },

  // eeco
  {
    metaTitle:
      "Maruti Suzuki Eeco for Sale Hyderabad | Maruti Eeco On Road Price | Deals on Maruti Eeco",
    metaDescription:
      "Maruti Suzuki Eeco On Road Pice in Hyderabad. Eeco available in petrol & cng variants . visit nearest Gem Motors showroom for Eeco offers.",
    id: "eeco",
    category: "arena",
    name: "Maruti Eeco",
    tag: "Makes every journey special",
    pic: require("../assets/arena/eeco.webp"),
    price: 532000,
    fuel: "Petrol / CNG",
    transmission: "5MT",
    cc: "1197",
    variants: [
      {
        variant: "5 STR STD",
        price: 532000,
      },
      {
        variant: "7 STR STD",
        price: 561000,
      },
      {
        variant: "5 STR AC",
        price: 568000,
      },
      {
        variant: "5 STR AC CNG",
        price: 658000,
      },
    ],
    banners: [
      {
        img: require("../assets/eeco/1.webp"),
      },
      {
        img: require("../assets/eeco/2.webp"),
      },
      {
        img: require("../assets/eeco/3.webp"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //max 6 items
      {
        title: "Length (mm)",
        value: 3675,
      },
      {
        title: "Width (mm)",
        value: 1475,
      },
      {
        title: "Height (mm)",
        value: "1825",
      },
      {
        title: "Fuel Tank(L)",
        value: 32,
      },
      {
        title: "Seating",
        value: 5 / 7,
      },
      {
        title: "Bootspace(L)",
        value: "-",
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: "1197",
      },
      {
        title: "Engine Type",
        value: "K12N",
      },
      {
        title: "Cylinders",
        value: "4",
      },
      {
        title: "Max Power",
        value: "59.4@6000",
      },
      {
        title: "Max Torque",
        value: "104.4@3000",
      },
      {
        title: "Transmission",
        value: "5MT",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "Disc",
      },
      {
        title: "Rear",
        value: "Drum",
      },
      {
        title: "Kerb",
        value: "935",
      },
      {
        title: "Gross Weight",
        value: "-",
      },
    ],
  },

  // ertiga
  {
    metaTitle:
      "Maruti Suzuki Ertiga On Road Price Hyderabad | Offers on Maruti Ertiga",
    metaDescription:
      "Maruti Suzuki Ertiga on road price in Hyderabad. Maruti Suzuki 7 seater car offers. Maruti Ertiga specifications & features. Book a test drive",
    id: "ertiga",
    category: "arena",
    name: "Maruti Ertiga",
    tag: "Sculpted to stand out",
    pic: require("../assets/arena/ertiga.png"),
    price: 869000,
    fuel: "Petrol",
    transmission: "5MT / 6AT",
    cc: "1462",
    variants: [
      {
        variant: "LXI(O)",
        price: 869000,
      },
      {
        variant: "VXI(O)",
        price: 983000,
      },
      {
        variant: "VXI(O) CNG",
        price: 1078000,
      },
      {
        variant: "ZXI(O)",
        price: 1093000,
      },
      {
        variant: "VXI",
        price: 1123000,
      },
      {
        variant: "ZXI+",
        price: 1163000,
      },
      {
        variant: "ZXI(O)",
        price: 1188000,
      },
      {
        variant: "ZXI 6AT",
        price: 1233000,
      },
      {
        variant: "ZXI+ 6AT",
        price: 1303000,
      },
    ],
    banners: [
      {
        img: require("../assets/ertiga/1.jpg"),
      },
      {
        img: require("../assets/ertiga/2.jpg"),
      },
      {
        img: require("../assets/ertiga/3.jpg"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //max 6 items
      {
        title: "Length (mm)",
        value: 4395,
      },
      {
        title: "Width (mm)",
        value: 1735,
      },
      {
        title: "Height (mm)",
        value: "1690",
      },
      {
        title: "Fuel Tank(L)",
        value: 45,
      },
      {
        title: "Seating",
        value: 7,
      },
      {
        title: "Bootspace(L)",
        value: "-",
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: "1462",
      },
      {
        title: "Engine Type",
        value: "K15C Smart Hybrid",
      },
      {
        title: "Cylinders",
        value: "-",
      },
      {
        title: "Max Power",
        value: "75.8@6000",
      },
      {
        title: "Max Torque",
        value: "136.8@4400",
      },
      {
        title: "Transmission",
        value: "5MT / 6AT",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "Disc",
      },
      {
        title: "Rear",
        value: "Drum",
      },
      {
        title: "Kerb",
        value: "1150-1205",
      },
      {
        title: "Gross Weight",
        value: "1760",
      },
    ],
  },

  // swift
  {
    metaTitle:
      "Maruti Suzuki swift On Road Price| Maruti swift Offers | swift Mileage",
    metaDescription:
      "Maruti Suzuki swift on road price in Hyderabad. Visit nearest Gem Maruti Suzuki Showroom to get Offers on swift. Maruti Suzuki swift Mileage. Book A Test Drive",
    id: "swift",
    category: "arena",
    name: "Maruti Swift",
    tag: "Too Savage, Too Stylish",
    pic: require("../assets/arena/swift.webp"),
    price: 599450,
    fuel: "Petrol / CNG",
    transmission: "5MT / 5AMT",
    cc: "1197",
    variants: [
      {
        variant: "LXI",
        price: 649000,
      },
      {
        variant: "VXI",
        price: 729500,
      },
      {
        variant: "VXI AMT",
        price: 779500,
      },
      {
        variant: "VXI (O)",
        price: 756500,
      },
      {
        variant: "VXI (O) AMT",
        price: 806500,
      },
      {
        variant: "ZXI",
        price: 829500,
      },
      {
        variant: "ZXI AMT",
        price: 879500,
      },
      {
        variant: "ZXI +",
        price: 899500,
      },
      {
        variant: "ZXI + AMT",
        price: 949500,
      },
    ],
    banners: [
      {
        img: require("../assets/swift/1.webp"),
      },
      {
        img: require("../assets/swift/2.webp"),
      },
      {
        img: require("../assets/swift/3.webp"),
      },
      {
        img: require("../assets/swift/4.webp"),
      },
      {
        img: require("../assets/swift/5.webp"),
      },
      {
        img: require("../assets/swift/6.webp"),
      },
      {
        img: require("../assets/swift/7.webp"),
      },
      {
        img: require("../assets/swift/8.webp"),
      },
      {
        img: require("../assets/swift/9.webp"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //max 6 items
      {
        title: "Length (mm)",
        value: 3845,
      },
      {
        title: "Width (mm)",
        value: 1735,
      },
      {
        title: "Height (mm)",
        value: "1530",
      },
      {
        title: "Fuel Tank(L)",
        value: 37,
      },
      {
        title: "Seating",
        value: 5,
      },
      {
        title: "Bootspace(L)",
        value: "268",
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: "1197",
      },
      {
        title: "Engine Type",
        value: "Advanced K series Dual Jet",
      },
      {
        title: "Cylinders",
        value: "4",
      },
      {
        title: "Max Power",
        value: "66@6000",
      },
      {
        title: "Max Torque",
        value: "89.73@6000",
      },
      {
        title: "Transmission",
        value: "5MT / 5AMT",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "Disc",
      },
      {
        title: "Rear",
        value: "Drum",
      },
      {
        title: "Kerb",
        value: "875-905",
      },
      {
        title: "Gross Weight",
        value: "1335",
      },
    ],
  },

  // wagnor
  {
    metaTitle:
      "Maruti Suzuki Wagnor On Road Price| Maruti Wagnor Offers | Wagnor Mileage",
    metaDescription:
      "Maruti Suzuki Wagnor on road price in Hyderabad. Visit nearest Gem Maruti Suzuki Showroom to get Offers on Wagnor. Maruti Suzuki Wagnor Mileage. Book A Test Drive",
    id: "wagonR",
    category: "arena",
    name: "Maruti WagonR",
    tag: "Strong on Looks",
    pic: require("../assets/arena/wagonr.png"),
    price: 554500,
    fuel: "Petrol",
    transmission: "MT / AGS",
    cc: "1197",
    variants: [
      {
        variant: "Lxi 1.0L",
        price: 554500,
      },
      {
        variant: "Vxi 1.0L",
        price: 599500,
      },
      {
        variant: "Zxi 1.2L",
        price: 628000,
      },
      {
        variant: "Lxi CNG 1.0L",
        price: 644500,
      },
      {
        variant: "Vxi AGS 1.0L",
        price: 649500,
      },
      {
        variant: "Zxi+ 1.2L",
        price: 675500,
      },
      {
        variant: "Zxi AGS 1.2L",
        price: 678000,
      },
      {
        variant: "Vxi CNG 1.0L",
        price: 689500,
      },
      {
        variant: "ZXI+ 1.2L AGS",
        price: 725500,
      },
    ],
    banners: [
      {
        img: require("../assets/wagonR/1.png"),
      },
      {
        img: require("../assets/wagonR/2.png"),
      },
      {
        img: require("../assets/wagonR/3.png"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //max 6 items
      {
        title: "Length (mm)",
        value: 3655,
      },
      {
        title: "Width (mm)",
        value: 1620,
      },
      {
        title: "Height (mm)",
        value: "1675",
      },
      {
        title: "Fuel Tank(L)",
        value: 32,
      },
      {
        title: "Seating",
        value: 5,
      },
      {
        title: "Bootspace(L)",
        value: "341",
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: "1197",
      },
      {
        title: "Engine Type",
        value: "K12N",
      },
      {
        title: "Cylinders",
        value: "4",
      },
      {
        title: "Max Power",
        value: "66@6000",
      },
      {
        title: "Max Torque",
        value: "113@4400",
      },
      {
        title: "Transmission",
        value: "MT / AGS",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "Disc",
      },
      {
        title: "Rear",
        value: "Drum",
      },
      {
        title: "Kerb",
        value: "810-850",
      },
      {
        title: "Gross Weight",
        value: "1340",
      },
    ],
  },

  // supercarry
  {
    metaTitle:
      "Maruti Suzuki Super Carry On Road Price & Offers in Hyderabad | Maruti Suzuki Commercial Dealers",
    metaDescription:
      "Maruti Suzuki Super Carry price. Maruti Suzuki Super Carry Dealers . Super Carry is a small commercial vehicle, available in Petrol, CNG variants. It is used as a mini loading and pickup truck. It offers attractive mileage. For Offers Call Now",
    id: "super-carry",
    category: "commercial",
    name: "Super Carry",
    tag: null,
    pic: require("../assets/goods/carry.webp"),
    price: 473000,
    fuel: "Petrol / CNG",
    transmission: "MT",
    cc: "1196",
    variants: [
      {
        variant: "Super Carry petrol",
        price: 488000,
      },
      {
        variant: "BS6 Cab Chasis",
        price: 473000,
      },
      {
        variant: "Super Carry CNG Chasis",
        price: 615500,
      },
      {
        variant: "Super Carry CNG",
        price: 630000,
      },
    ],
    banners: [
      {
        img: require("../assets/carry/1.jpg"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //max 6 items
      {
        title: "Length (mm)",
        value: 3800,
      },
      {
        title: "Width (mm)",
        value: 1562,
      },
      {
        title: "Height (mm)",
        value: "1883",
      },
      {
        title: "Fuel Tank(L)",
        value: 30,
      },
      {
        title: "Seating",
        value: 2,
      },
      {
        title: "Bootspace(L)",
        value: "-",
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: "1196",
      },
      {
        title: "Engine Type",
        value: "Advanced K-Series Dual Jet, Dual WT I",
      },
      {
        title: "Cylinders",
        value: "4",
      },
      {
        title: "Max Power",
        value: "54@6000",
      },
      {
        title: "Max Torque",
        value: "98@3000",
      },
      {
        title: "Transmission",
        value: "MT",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "-",
      },
      {
        title: "Rear",
        value: "-",
      },
      {
        title: "Kerb",
        value: "860",
      },
      {
        title: "Gross Weight",
        value: "1600",
      },
    ],
  },

  // supercargo
  {
    metaTitle:
      "Maruti Suzuki Eeco Cargo On Road Price & Offers in Hyderabad | Eecocargo Offers",
    metaDescription:
      "Maruti Suzuki Eeco Cargo on road price inHyderabad. It’s available in Petrol, CNG variants. Maruti Suzuki Eeco Cargo Offers in Hyderabad Eeco Cargo offers good mileage, ride quality, and safety features.",
    id: "eeco-cargo",
    category: "commercial",
    name: "EECO Cargo",
    tag: null,
    pic: require("../assets/goods/cargo.webp"),
    price: 478500,
    fuel: "Petrol / CNG",
    transmission: "MT",
    cc: "1196",
    variants: [
      {
        variant: "Cargo Petrol",
        price: 478500,
      },
      {
        variant: "Cargo CNG",
        price: 573400,
      },
      {
        variant: "Cargo CNG AC",
        price: 615200,
      },
    ],
    banners: [
      {
        img: require("../assets/cargo/1.jpg"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //max 6 items
      {
        title: "Length (mm)",
        value: 3675,
      },
      {
        title: "Width (mm)",
        value: 1475,
      },
      {
        title: "Height (mm)",
        value: "1825",
      },
      {
        title: "Fuel Tank(L)",
        value: 40,
      },
      {
        title: "Seating",
        value: 2,
      },
      {
        title: "Bootspace(L)",
        value: "-",
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: "1196",
      },
      {
        title: "Engine Type",
        value: "G12B",
      },
      {
        title: "Cylinders",
        value: "4",
      },
      {
        title: "Max Power",
        value: "54@6000",
      },
      {
        title: "Max Torque",
        value: "98@3000",
      },
      {
        title: "Transmission",
        value: "MT",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "-",
      },
      {
        title: "Rear",
        value: "-",
      },
      {
        title: "Kerb",
        value: "920",
      },
      {
        title: "Gross Weight",
        value: "-",
      },
    ],
  },

  // v-storm
  {
    metaTitle:
      "Suzuki V-Strom SX On Road Price in Hyderabad | V Strom SX Offers",
    metaDescription:
      "Suzuki V-Strom SX On-road Price in  hyderabad.Suzuki V-Strom 250 mileage. Visit nearest Gem Suzuki showroom for more offers on Suzuki V-strom sx. ",
    id: "v-storm",
    category: "suzuki",
    name: "V-Storm SX",
    tag: null,
    pic: require("../assets/v-storm/1.png"),
    price: 213894,
    fuel: "Petrol",
    transmission: "MT",
    cc: "249",
    variants: [
      {
        variant: "V-Storm SX",
        price: 213894,
      },
    ],
    banners: [
      {
        img: require("../assets/v-storm/1.png"),
      },
      {
        img: require("../assets/v-storm/2.jpg"),
      },
      {
        img: require("../assets/v-storm/3.jpg"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //max 6 items
      {
        title: "Length (mm)",
        value: 2180,
      },
      {
        title: "Width (mm)",
        value: 880,
      },
      {
        title: "Height (mm)",
        value: "1355",
      },
      {
        title: "Fuel Tank(L)",
        value: 12,
      },
      {
        title: "Seating",
        value: 2,
      },
      {
        title: "Bootspace(L)",
        value: "-",
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: "249",
      },
      {
        title: "Engine Type",
        value: "4-Stroke oil cooled SOHC",
      },
      {
        title: "Cylinders",
        value: "-",
      },
      {
        title: "Max Power",
        value: "26.5@9300",
      },
      {
        title: "Max Torque",
        value: "22.2@7300",
      },
      {
        title: "Transmission",
        value: "MT",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "Disc,Single",
      },
      {
        title: "Rear",
        value: "Disc",
      },
      {
        title: "Kerb",
        value: "167",
      },
      {
        title: "Gross Weight",
        value: "-",
      },
    ],
  },

  // sf-250
  {
    metaTitle:
      "Suzuki Gixxer SF-250 / 250 On Road Price in Hyderabad |Gixxer SF-250 Offers",
    metaDescription:
      "Suzuki Gixxer SF-250 / 250 On-road Price in hyderabad. Suzuki Gixxer SF-250 / 250 mileage. Visit nearest Gem Suzuki showroom for more offers on Suzuki Gixxer SF-250 / 250.",
    id: "s-250",
    category: "suzuki",
    name: "Gixxer SF-250 / 250",
    tag: null,
    pic: require("../assets/s-250/1.png"),
    price: 183692,
    fuel: "Petrol",
    transmission: "MT",
    cc: "249",
    variants: [
      {
        variant: "Gixxer 250 Standard Edition",
        price: 183692,
      },
      {
        variant: "Gixxer 250 Ride Connect Edition",
        price: 200293,
      },
      {
        variant: "Gixxer SF 250 Standard Edition",
        price: 194393,
      },
      {
        variant: "Gixxer SF 250 Ride Connect Edition",
        price: 207293,
      },
      {
        variant: "Gixxer SF 250 Race Edition SE",
        price: 195193,
      },
      {
        variant: "Gixxer SF 250 Race Ride Connect Edition",
        price: 207792,
      },
    ],
    banners: [
      {
        img: require("../assets/s-250/1.png"),
      },
      {
        img: require("../assets/s-250/2.png"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //max 6 items
      {
        title: "Length (mm)",
        value: 2010,
      },
      {
        title: "Width (mm)",
        value: 740 / 805,
      },
      {
        title: "Height (mm)",
        value: "1035",
      },
      {
        title: "Fuel Tank(L)",
        value: 12,
      },
      {
        title: "Seating",
        value: 2,
      },
      {
        title: "Bootspace(L)",
        value: "-",
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: "249",
      },
      {
        title: "Engine Type",
        value: "4-Stroke oil cooled SOHC",
      },
      {
        title: "Cylinders",
        value: "-",
      },
      {
        title: "Max Power",
        value: "26.5@9300",
      },
      {
        title: "Max Torque",
        value: "22.2@7300",
      },
      {
        title: "Transmission",
        value: "MT",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "Disc",
      },
      {
        title: "Rear",
        value: "Disc",
      },
      {
        title: "Kerb",
        value: "161",
      },
      {
        title: "Gross Weight",
        value: "-",
      },
    ],
  },

  // sf-gixxer
  {
    metaTitle: "Suzuki Gixxer SF On Road Price in Hyderabad |Gixxer SF Offers",
    metaDescription:
      "Suzuki Gixxer SF On-road Price in hyderabad. Suzuki Gixxer SF mileage. Visit nearest Gem Suzuki showroom for more offers on Suzuki Gixxer SF.",
    id: "gixxer-sf",
    category: "suzuki",
    name: "Gixxer SF",
    tag: null,
    pic: require("../assets/sf/1.png"),
    price: 137091,
    fuel: "Petrol",
    transmission: "MT",
    cc: "155",
    variants: [
      {
        variant: "Gixxer Standard Edition",
        price: 137091,
      },
      {
        variant: "Gixxer Ride Connect Edition",
        price: 143193,
      },
      {
        variant: "Gixxer SF Standard Edition",
        price: 139393,
      },
      {
        variant: "Gixxer SF Ride Connect Edition",
        price: 148193,
      },
    ],
    banners: [
      {
        img: require("../assets/sf/1.png"),
      },
      {
        img: require("../assets/sf/2.png"),
      },
      {
        img: require("../assets/sf/1.jpg"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //max 6 items
      {
        title: "Length (mm)",
        value: 2025 / 2020,
      },
      {
        title: "Width (mm)",
        value: 715 / 800,
      },
      {
        title: "Height (mm)",
        value: "1035",
      },
      {
        title: "Fuel Tank(L)",
        value: 12,
      },
      {
        title: "Seating",
        value: 2,
      },
      {
        title: "Bootspace(L)",
        value: "-",
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: "155",
      },
      {
        title: "Engine Type",
        value: "4-Stroke air cooled SOHC",
      },
      {
        title: "Cylinders",
        value: "-",
      },
      {
        title: "Max Power",
        value: "13.6@8000",
      },
      {
        title: "Max Torque",
        value: "13.8@6000",
      },
      {
        title: "Transmission",
        value: "MT",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "Disc",
      },
      {
        title: "Rear",
        value: "Disc",
      },
      {
        title: "Kerb",
        value: "148 / 141",
      },
      {
        title: "Gross Weight",
        value: "-",
      },
    ],
  },

  // hayabusa
  {
    metaTitle: "Suzuki Hayabusa On Road Price in Hyderabad |Hayabusa Offers",
    metaDescription:
      "Suzuki Hayabusa On-road Price in hyderabad. Suzuki Hayabusa Street mileage. Visit nearest Gem Suzuki showroom for more offers on Suzuki Hayabusa.",
    id: "hayabusa",
    category: "suzuki",
    name: "Hayabusa",
    tag: null,
    pic: require("../assets/hayabusa/1.jpg"),
    price: 1697716,
    fuel: "Petrol",
    transmission: "MT",
    cc: "1340",
    variants: [
      {
        variant: "Hayabusa",
        price: 1697716,
      },
    ],
    banners: [
      {
        img: require("../assets/hayabusa/1.jpg"),
      },
      {
        img: require("../assets/hayabusa/2.jpg"),
      },
      {
        img: require("../assets/hayabusa/3.jpg"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //max 6 items
      {
        title: "Length (mm)",
        value: 2180,
      },
      {
        title: "Width (mm)",
        value: 735,
      },
      {
        title: "Height (mm)",
        value: 1165,
      },
      {
        title: "Fuel Tank(L)",
        value: 20,
      },
      {
        title: "Seating",
        value: 2,
      },
      {
        title: "Bootspace(L)",
        value: "-",
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: "1340",
      },
      {
        title: "Engine Type",
        value: "4-Stroke liquid cooled DOHC",
      },
      {
        title: "Cylinders",
        value: "-",
      },
      {
        title: "Max Power",
        value: "-",
      },
      {
        title: "Max Torque",
        value: "-",
      },
      {
        title: "Transmission",
        value: "MT",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "Twin Disc",
      },
      {
        title: "Rear",
        value: "Single Disc",
      },
      {
        title: "Kerb",
        value: "266",
      },
      {
        title: "Gross Weight",
        value: "-",
      },
    ],
  },

  // avenis
  {
    metaTitle: "Suzuki Avenis On Road Price in Hyderabad |Avenis SF Offers",
    metaDescription:
      "Suzuki Avenis On-road Price in hyderabad. Suzuki Avenis mileage. Visit nearest Gem Suzuki showroom for more offers on Suzuki Avenis.",
    id: "avenis",
    category: "suzuki",
    name: "Avenis",
    tag: null,
    pic: require("../assets/avenis/1.jpg"),
    price: 95050,
    fuel: "Petrol",
    transmission: "MT",
    cc: "124",
    variants: [
      {
        variant: "Avenis",
        price: 95050,
      },
      {
        variant: "Avenis Race Edition",
        price: 95093,
      },
    ],
    banners: [
      {
        img: require("../assets/avenis/1.jpg"),
      },
      {
        img: require("../assets/avenis/2.jpg"),
      },
      {
        img: require("../assets/avenis/3.jpg"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //max 6 items
      {
        title: "Length (mm)",
        value: 1895,
      },
      {
        title: "Width (mm)",
        value: 710,
      },
      {
        title: "Height (mm)",
        value: 1175,
      },
      {
        title: "Fuel Tank(L)",
        value: 5.2,
      },
      {
        title: "Seating",
        value: 2,
      },
      {
        title: "Bootspace(L)",
        value: "-",
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: "124",
      },
      {
        title: "Engine Type",
        value: "4-Stroke air cooled",
      },
      {
        title: "Cylinders",
        value: "-",
      },
      {
        title: "Max Power",
        value: "-",
      },
      {
        title: "Max Torque",
        value: "10@5500",
      },
      {
        title: "Transmission",
        value: "MT",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "Disc",
      },
      {
        title: "Rear",
        value: "Drum",
      },
      {
        title: "Kerb",
        value: "106",
      },
      {
        title: "Gross Weight",
        value: "-",
      },
    ],
  },

  // access
  {
    metaTitle:
      "Suzuki Access 125 On Road Price in Hyderabad |Access 125 Mileage",
    metaDescription:
      "Suzuki Access 125 On-road Price in hyderabad. Suzuki Access 125 mileage. Visit nearest Gem Suzuki showroom for more offers on Suzuki Access 125.",
    id: "access-125",
    category: "suzuki",
    name: "Access 125",
    tag: null,
    pic: require("../assets/access/1.png"),
    price: 82192,
    fuel: "Petrol",
    transmission: "MT",
    cc: "124",
    variants: [
      {
        variant: "Standard Edition Drum Brake",
        price: 82192,
      },
      {
        variant: "Standard Edition Disc Brake",
        price: 82192,
      },
      {
        variant: "Special Edition Disc Brake",
        price: 88851,
      },
      {
        variant: "Ride Connect Edition Disc Brake",
        price: 92792,
      },
    ],
    banners: [
      {
        img: require("../assets/access/1.png"),
      },
      {
        img: require("../assets/access/2.png"),
      },
      {
        img: require("../assets/access/3.png"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //max 6 items
      {
        title: "Length (mm)",
        value: 1870,
      },
      {
        title: "Width (mm)",
        value: 690,
      },
      {
        title: "Height (mm)",
        value: 1160,
      },
      {
        title: "Fuel Tank(L)",
        value: 5,
      },
      {
        title: "Seating",
        value: 2,
      },
      {
        title: "Bootspace(L)",
        value: "-",
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: "124",
      },
      {
        title: "Engine Type",
        value: "4-Stroke air cooled",
      },
      {
        title: "Cylinders",
        value: "-",
      },
      {
        title: "Max Power",
        value: "-",
      },
      {
        title: "Max Torque",
        value: "10@5500",
      },
      {
        title: "Transmission",
        value: "MT",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "Disc/Drum",
      },
      {
        title: "Rear",
        value: "Drum",
      },
      {
        title: "Kerb",
        value: "103/104",
      },
      {
        title: "Gross Weight",
        value: "-",
      },
    ],
  },

  // burgman-street
  {
    metaTitle:
      "Suzuki Burgman Street On Road Price in Hyderabad |Burgman Street Offers",
    metaDescription:
      "Suzuki Burgman Street On-road Price in hyderabad. Suzuki Burgman Street mileage. Visit nearest Gem Suzuki showroom for more offers on Suzuki Burgman Street.",
    id: "burgman-street",
    category: "suzuki",
    name: "Burgman Street",
    tag: null,
    pic: require("../assets/street/1.jpg"),
    price: 96292,
    fuel: "Petrol",
    transmission: "MT",
    cc: "124",
    variants: [
      {
        variant: "Standard Edition",
        price: 96292,
      },
      {
        variant: "Ride Connect Edition",
        price: 100291,
      },
      {
        variant: "Burgman Street EX",
        price: 116491,
      },
    ],
    banners: [
      {
        img: require("../assets/street/1.jpg"),
      },
      {
        img: require("../assets/street/2.jpg"),
      },
      {
        img: require("../assets/street/3.jpg"),
      },
    ],

    // dimensions & capacity
    dimensions: [
      //max 6 items
      {
        title: "Length (mm)",
        value: 1880,
      },
      {
        title: "Width (mm)",
        value: 715,
      },
      {
        title: "Height (mm)",
        value: 1140,
      },
      {
        title: "Fuel Tank(L)",
        value: 5.5,
      },
      {
        title: "Seating",
        value: 2,
      },
      {
        title: "Bootspace(L)",
        value: "-",
      },
    ],

    //engine & transmission
    engine: [
      {
        title: "Capacity(cc)",
        value: "124",
      },
      {
        title: "Engine Type",
        value: "4-Stroke air cooled",
      },
      {
        title: "Cylinders",
        value: "-",
      },
      {
        title: "Max Power",
        value: "-",
      },
      {
        title: "Max Torque",
        value: "10@5500",
      },
      {
        title: "Transmission",
        value: "MT",
      },
    ],

    //weights & brakes
    weight: [
      {
        title: "Front",
        value: "Disc",
      },
      {
        title: "Rear",
        value: "Drum",
      },
      {
        title: "Kerb",
        value: "110",
      },
      {
        title: "Gross Weight",
        value: "-",
      },
    ],
  },
];
